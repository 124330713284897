import Web3Modal from "web3modal";
import { ethers } from "ethers";
import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
import Authereum from "authereum";
import jwt_decode from "jwt-decode";

const state = {
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainName: null,
  providerEthers: null, // this is "provider" for Ethers.js
  isConnected: false,
  providerW3m: null, // this is "provider" from Web3Modal
  web3Modal: null,
  isLoggedIn: false,
  userToken: null,
  discordUserImg:null,
  discordUsername:null,
  SUPAUsername:null,
  discordRole:0,
  accountDetails: null,
  groupDetails: null,
  discordRefreshedTimes:0,
  discordUserID:null,
};

const getters = {
  getActiveAccount(state) {
    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getActiveBalanceEth(state) {
    return ethers.utils.formatEther(state.activeBalance);
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainName(state) {
    return state.chainName;
  },
  getProviderEthers(state) {
    return state.providerEthers;
  },
  getWeb3Modal(state) {
    return state.web3Modal;
  },
  isUserConnected(state) {
    return state.isConnected;
  },
  isUserLoggedIn(state) {
  
    return state.isLoggedIn;
  },
  getUserToken(state){
    return state.userToken;
  },
   getDiscordUsername(state){
    return state.discordUsername;
  },
   getDiscordUserImg(state){
    return state.discordUserImg;
  },
  getSUPAUsername(state){
    return state.SUPAUsername;
  },
  getDiscordRole(state){
    return state.discordRole;
  },
  getDiscordID(state){
    return state.discordUserID;
  },
  getAccountDetails(state) {
    return state.accountDetails;
  },
  getGroupDetails(state) {
    return state.groupDetails;
  },
};

const actions = {

  async initWeb3Modal({ commit,dispatch }) {
    const providerOptions = {
      // MetaMask is enabled by default
      // Find other providers here: https://github.com/Web3Modal/web3modal/tree/master/docs/providers
      burnerconnect: {
        package: BurnerConnectProvider // required
      },
      authereum: {
        package: Authereum // required
      }
    };
    
    const w3mObject = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions // required
    });

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    window.ethereum.autoRefreshOnNetworkChange = false;

    // if the user is flagged as already connected, automatically connect back to Web3Modal
    if (localStorage.getItem('isConnected') === "true") {
      let providerW3m = await w3mObject.connect();
      commit("setIsConnected", true);

      commit("setActiveAccount", window.ethereum.selectedAddress);
      commit("setChainData", window.ethereum.chainId);
      commit("setEthersProvider", providerW3m);
      
      actions.fetchActiveBalance({ commit });
      dispatch('contracts/fetchRemainingSUPAVortexNFTs', null, { root: true })
      dispatch("contracts/storeSUPAVortexAddress", null, { root: true })
      dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1",total:2000}, { root: true });

      dispatch("contracts/storeNFTAddress",{name:"SUPAVerseComicI1C1P1"}, { root: true });
    }

    commit("setWeb3ModalInstance", w3mObject);
  },

  async connectWeb3Modal({ commit,dispatch }) {
    let providerW3m = await state.web3Modal.connect();
    commit("setIsConnected", true);

    commit("setActiveAccount", window.ethereum.selectedAddress);
    commit("setChainData", window.ethereum.chainId);
    commit("setEthersProvider", providerW3m);
    actions.fetchActiveBalance({ commit });
    dispatch('contracts/fetchRemainingSUPAVortexNFTs', null, { root: true })
    dispatch("contracts/storeSUPAVortexAddress", null, { root: true })
    dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1"}, { root: true });
   
    dispatch("contracts/storeNFTAddress",{name:"SUPAVerseComicI1C1P1"}, { root: true });

  },
  async getIsLoggedIn({ commit }) {
    if(localStorage.getItem('jwtToken') !== null){
      var token=localStorage.getItem('jwtToken').toString();
      var decoded = jwt_decode(token);
      const timestamp = Math.round(Date.now() / 1000); 
      if (decoded.exp>timestamp){

        
          let formData = new FormData();
          formData.append('token', token);
           fetch('https://api.supa.foundation/refreshDiscordUser', {
       
        method: "POST",
     body: formData,
    
   })
   .then(response => response.json()) 
   .then(json => {
     
      if (json.error==true){
  

        commit("setIsLoggedIn", false);
        commit("deleteUserToken");
       
     }else{
       if(state.discordRefreshedTimes<2){
      var data = { jwt: token  };
fetch('https://api2.supa.foundation/refreshDiscordRole', {

method: "POST",
body: JSON.stringify(data),
headers: {
'Content-Type': 'application/json'
},

})
.then(response => response.json()) 
.then(json2 => {
if (json2.error>0){
console.log(json2)
commit("setIsLoggedIn", false);
commit("deleteUserToken");
}else{
  json.userinfo[0].hasTitan=json2.userInfo.hasTitan
  json.userinfo[0].hasIDO=json2.userInfo.hasIDO
  json.userinfo[0].hasGenesis=json2.userInfo.hasGenesis
  commit("setIsLoggedIn", true);
  
   commit("saveAccountDetails",json.userinfo[0])
   commit("saveGroupInfo",json.group)
   //console.log( jwtToken.token)
   commit("setDiscordRefreshedTimes")
   commit("setUserToken",localStorage.getItem('jwtToken'))

}})
     } else{
      commit("setIsLoggedIn", true);
  
      commit("saveAccountDetails",json.userinfo[0])
      commit("saveGroupInfo",json.group)
      //console.log( jwtToken.token)
      commit("setUserToken",localStorage.getItem('jwtToken'))
     }
 
     }
    
    
    })
       






       
      }else{
 
        commit("setIsLoggedIn", false);
        commit("deleteUserToken");
      }

    }
    
     },
     async connectDiscord({ commit }, params) {
    
      if(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(params.token)==true){
        var decoded = jwt_decode(params.token.toString());
        const timestamp = Math.round(Date.now() / 1000); 
        if (decoded.exp>timestamp){
            commit("setIsLoggedIn", true);
   //console.log( jwtToken.token)
   commit("setUserToken",params.token)
        }
      }
    
     

 },
async disconnectDiscord({ commit }) {
   commit("deleteUserToken");
   commit("setIsLoggedIn", false);
 },

  async disconnectWeb3Modal({ commit }) {
    commit("disconnectWallet");
    commit("setIsConnected", false);
  },

  async ethereumListener({ commit,dispatch }) {

    window.ethereum.on('accountsChanged', (accounts) => {
      if (state.isConnected) {
        commit("setActiveAccount", accounts[0]);
        commit("setEthersProvider", state.providerW3m);
        actions.fetchActiveBalance({ commit });
        dispatch('contracts/fetchRemainingSUPAVortexNFTs', null, { root: true })
        dispatch("contracts/storeSUPAVortexAddress", null, { root: true })
        dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1"}, { root: true });
       
        dispatch("contracts/storeNFTAddress",{name:"SUPAVerseComicI1C1P1"}, { root: true });

      }
    });

    window.ethereum.on('chainChanged', (chainId) => {
      commit("setChainData", chainId);
      commit("setEthersProvider", state.providerW3m);
      actions.fetchActiveBalance({ commit });
      dispatch('contracts/fetchRemainingSUPAVortexNFTs', null, { root: true })
      dispatch("contracts/storeSUPAVortexAddress", null, { root: true })
     dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1"}, { root: true });
   
    dispatch("contracts/storeNFTAddress",{name:"SUPAVerseComicI1C1P1"}, { root: true });

    });

  },

  async fetchActiveBalance({ commit }) {
    let balance = await state.providerEthers.getBalance(state.activeAccount);
    commit("setActiveBalance", balance);
  }
  
};

const mutations = {

  async disconnectWallet(state) {
    state.activeAccount = null;
    state.activeBalance = 0;
    state.providerEthers = null;
    if (state.providerW3m.close && state.providerW3m !== null) {
      await state.providerW3m.close();
    }
    state.providerW3m = null;
    await state.web3Modal.clearCachedProvider();
    
    window.location.href = '../'; // redirect to the Main page
  },

  setActiveAccount(state, selectedAddress) {
    state.activeAccount = selectedAddress;
  },
  setDiscordRefreshedTimes(){
    state.discordRefreshedTimes++;
  },
  deleteUserToken(state) {
    localStorage.removeItem('jwtToken');
    state.discordUserImg=null;
    state.discordUserID=null;
    state.discordUsername=null;
    state.SUPAUsername=null;
    state.discordRole=0;
   },

   setUserToken(state,token){

    state.userToken=token.toString();
    localStorage.setItem('jwtToken',token.toString());
      var decoded = jwt_decode(token.toString());
 // console.log(token.toString())
     state.discordUserImg=decoded.img;
     state.discordUsername=decoded.discordName;
     state.discordUserID=decoded._id;
     state.SUPAUsername=decoded.nick;
     state.discordRole=decoded.discordRole;
    },

  setActiveBalance(state, balance) {
    state.activeBalance = balance;
  },
  setAccountDetails(state, accountInfo) {
    state.accountDetails = accountInfo;

  
  },
  setGroupInfo(state, groupInfo) {
    state.groupDetails = groupInfo;

  },
  saveAccountDetails(state, accountInfo) {
    state.accountDetails = accountInfo;

    localStorage.setItem('accountInfo',JSON.stringify(accountInfo))
  
  },

  saveGroupInfo(state, groupInfo) {
    state.groupDetails = groupInfo;
    localStorage.setItem('groupInfo',JSON.stringify(groupInfo))

  },
  setChainData(state, chainId) {
    state.chainId = chainId;

    switch(chainId) {
      case "0x1":
        state.chainName = "Mainnet";
        break;
      case "0x2a":
        state.chainName = "Kovan";
        break;
      case "0x3":
        state.chainName = "Ropsten";
        break;
      case "0x4":
        state.chainName = "Rinkeby";
        break;
      case "0x5":
        state.chainName = "Goerli";
        break;
        case "0x13881":
          state.chainName = "Mumbai";
          break;
        case "0xfa":
        state.chainName = "Fantom Network";
        break;
        case "0xfa2":
          state.chainName = "FTMTest";
          break;
      case "0x539": // 1337 (often used on localhost)
      case "0x1691": // 5777 (default in Ganache)
      default:
        state.chainName = "Localhost";
        break;
    }
  },

  async setEthersProvider(state, providerW3m) {
    state.providerW3m = providerW3m;
    state.providerEthers = new ethers.providers.Web3Provider(providerW3m);
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
    // add to persistent storage so that the user can be logged back in when revisiting website
    localStorage.setItem('isConnected', isConnected);
  },
  setIsLoggedIn(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
    localStorage.setItem('isLoggedIn', isLoggedIn);
//console.log("status"+localStorage.getItem('isLoggedIn'))

    // add to persistent storage so that the user can be logged back in when revisiting website
  },
  setWeb3ModalInstance(state, w3mObject) {
    state.web3Modal = w3mObject;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
