import { ethers } from "ethers";
import SUPAVortex from "../../contracts/SUPAVortex.json";
import SUPAVerseComicI1C1P1 from "../../contracts/SUPAVerseComicI1C1P1.json";
import addresses from "../../contracts/addresses.json";
import jwt_decode from "jwt-decode";
const state = {
  remainingNFTs: 0,  
  SUPAVortexAbi: null,
  SUPAVortexAddress: null,
  userMintedSUPAVortexNFT:true,
  SUPAVerseComicI1C1P1RemainingNFTs:0,
  UserMintedSUPAVerseComicI1C1P1NFT:true,
  SUPAVerseComicI1C1P1Abi:null,
  SUPAVerseComicI1C1P1Address:null

};

const getters = {
  getSUPAVortexRemainingBalance(state) {
    return state.remainingNFTs;
  },
  getSUPAVortexAbi(state) {
    return state.SUPAVortexAbi;
  },
  getSUPAVortexAddress(state) {
    return state.SUPAVortexAddress;
  },
  getUserMintedSUPAVortexNFT(state) {
    return  state.userMintedSUPAVortexNFT;
  },
  getSUPAVerseComicI1C1P1RemainingNFTs(state) {
    return state.SUPAVerseComicI1C1P1RemainingNFTs;
  },
  getSUPAVerseComicI1C1P1Abi(state) {
    return state.SUPAVerseComicI1C1P1Abi;
  },
  getSUPAVerseComicI1C1P1Address(state) {
    return state.SUPAVerseComicI1C1P1Address;
  },
  getUserMintedSUPAVerseComicI1C1P1NFT(state) {
    return  state.UserMintedSUPAVerseComicI1C1P1NFT;
  },
};

const actions = {
  async fetchRemainingSUPAVortexNFTs({ commit, rootState }) {
    let provider = rootState.accounts.providerEthers;
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let SUPAVortexAddress = addresses.SUPAVortex[chainIdDec];

    let contract = new ethers.Contract(SUPAVortexAddress, SUPAVortex.abi, provider);

    let num = await contract.totalSupply();
    var token=localStorage.getItem('jwtToken').toString();

    var decoded = jwt_decode(token);
    let discordUserID= decoded._id;
    let isMinted= await contract.userMinted(discordUserID);
   
    commit("setUserMintedSUPAVortexNFT", isMinted);
    commit("setRemainingSUPAVortexNFTs", 1000-num.toNumber());
  },
  async fetchRemainingNFTs({ commit, rootState },params) {
    let provider = rootState.accounts.providerEthers;
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let NFTAddress=null
    let NFTAbi=null
   
    if(params.name=="SUPAVerseComicI1C1P1"){
      NFTAddress = addresses.SUPAVerseComicI1C1P1[chainIdDec];
   
      NFTAbi=SUPAVerseComicI1C1P1.abi
     
    }else{
      //let NFTAddress=null
      //let NFTAbi=null
    }
    

    let contract = new ethers.Contract(NFTAddress, NFTAbi, provider);

    let num = await contract.totalSupply();
    var token=localStorage.getItem('jwtToken').toString();

    var decoded = jwt_decode(token);
    let discordUserID= decoded._id;
    let isMinted= await contract.userMinted(discordUserID);
    let toCommit={}
    toCommit.name=params.name
    toCommit.isMinted=isMinted
    toCommit.remaining=params.total-num.toNumber()
    if(params.name.length>0){
      
    commit("setUserMintedNFT", toCommit);
    commit("setRemainingNFTs", toCommit);
    }else{
      //toCommit={}
    }
  },
 
  storeSUPAVortexAbi({commit}) {
    commit("setSUPAVortexAbi", SUPAVortex.abi);
  },
  storeSUPAVortexAddress({ commit, rootState }) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let SUPAVortexAddress = addresses.SUPAVortex[chainIdDec];

    commit("setSUPAVortexAddress", SUPAVortexAddress);
  },

  storeNFTAbi({commit},params) {
    if(params.name=="SUPAVerseComicI1C1P1"){
    
    commit("setSUPAVerseComicI1C1P1Abi", SUPAVerseComicI1C1P1.abi);
 
    }else{
      //
    }
  },
  storeNFTAddress({ commit, rootState },params) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    if(params.name=="SUPAVerseComicI1C1P1"){
    
    let SUPANFTAddress = addresses.SUPAVerseComicI1C1P1[chainIdDec];

    commit("setSUPAVerseComicI1C1P1Address", SUPANFTAddress);
    }
    else{
      //
    }
  },
  storeNFTAddressAndABI({ commit, rootState },params) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    if(params.name=="SUPAVerseComicI1C1P1"){
      console.log(addresses.SUPAVerseComicI1C1P1[chainIdDec])
    let SUPANFTAddress = addresses.SUPAVerseComicI1C1P1[chainIdDec];

    commit("setSUPAVerseComicI1C1P1Address", SUPANFTAddress);
    }
    else{
      //
    }
  }
};

const mutations = {
  setRemainingSUPAVortexNFTs(state, _num) {
 
    state.remainingNFTs = _num;
  },
  setUserMintedSUPAVortexNFT(state, _result) {
    state.userMintedSUPAVortexNFT= _result
  },
  setSUPAVortexAbi(state, abi) {
    state.SUPAVortexAbi = abi;
  },
  setSUPAVortexAddress(state, address) {
    state.SUPAVortexAddress = address;
  },

  setRemainingNFTs(state, params) {
    if(params.name=="SUPAVerseComicI1C1P1"){
    state.SUPAVerseComicI1C1P1RemainingNFTs = params.remaining;
    }
  },
  setUserMintedNFT(state, params) {
    if(params.name=="SUPAVerseComicI1C1P1"){
      
    state.UserMintedSUPAVerseComicI1C1P1NFT= params.isMinted
    }
  },
  setSUPAVerseComicI1C1P1Abi(state, abi) {
    state.SUPAVerseComicI1C1P1Abi = abi;
  },
  setSUPAVerseComicI1C1P1Address(state, address) {
    state.SUPAVerseComicI1C1P1Address = address;
  }



};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
