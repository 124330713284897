<template>

 
      <vs-sidebar
       
        v-model="active"
        open
        background="#212121"
        text-white
        >
        <template #logo>
<a href="/" class="logo-link">
        <img src="/logo1.png" alt="" ></a>
           <a href="/" class="logo-link">       <h5>SUPA Portal</h5>
              </a>        </template>
    
   
      
          <vs-sidebar-item id="start" to="/">
            <template #icon>
              <i class='bx bx-home-alt' ></i>
            </template>
            Home
          </vs-sidebar-item>
           
         <!-- <vs-sidebar-item v-if="isUserConnected" id="Mint" to="/mint">
          <template #icon>
            <i class='bx bx-coin'></i>
          </template>
          Mint
        </vs-sidebar-item> -->
          <!-- <vs-sidebar-item v-if="isUserConnected" id="BagExplorer" to="/bagexplorer">
          <template #icon>
            <i class='bx bx-search-alt'></i>
          </template>
          Bag Explorer
        </vs-sidebar-item> -->
        
       
              <vs-sidebar-item id="FAQ" to="/faq">
            <template #icon>
              <i class='bx bx-info-circle'></i>
            </template>
            FAQ
          </vs-sidebar-item>
              <!-- <vs-sidebar-item id="Guide" to="/guide">
            <template #icon>
              <i class='bx bx-spreadsheet'></i>
            </template>
            Guide
          </vs-sidebar-item> -->
          <!-- <vs-sidebar-item id="OG" v-if="isUserConnected && isUserLoggedIn" to="/og">
            <template #icon>
              <i class='bx bx-cool'></i>
            </template>
            OG NFT
          </vs-sidebar-item> -->
          <vs-sidebar-item id="OG" v-if="isUserConnected && isUserLoggedIn" to="/mintog">
            <template #icon>
              <i class='bx bx-cool'></i>
            </template>
            OG NFT
          </vs-sidebar-item>
          <vs-sidebar-item id="minti1c1p1" v-if="isUserConnected && isUserLoggedIn" to="/minti1c1p1">
            <template #icon>
              <i class='bx bx-book-alt'></i>
            </template>
            SUPAVERSE COMIC
          </vs-sidebar-item>
      
     
      
        <template #footer >
          <vs-row hidden>
         <div class="alert alert-warning m-3" v-if="showChainAlert" role="alert">
          <span v-if="getChainName">Your currently selected chain is <strong>{{getChainName}}</strong>.</span>

          <span v-if="!getChainName">
            Please <a href="#" @click="connectWeb3Modal" class="alert-link">connect</a> with MetaMask
            or some other EVM-based wallet.
          </span>
        </div>
          </vs-row>
        </template>
      </vs-sidebar>


</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Sidebar",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected", "getWeb3Modal","isUserLoggedIn"]),

    showChainAlert() {
      switch (this.getChainName) {
        case "Polygon1":
          return false;
        default:
          return true;
      }
    }
  },
  created() {
    this.$store.dispatch("accounts/initWeb3Modal");
    this.$store.dispatch("accounts/ethereumListener");
    if(this.$route.path=="/"){
      this.active='start'
    }
    else if((this.$route.path=="/team")){
      this.active='team'

    }
    else if((this.$route.path=="/faq")){
      this.active='faq'


    }
    else if((this.$route.path=="/bagexplorer")){
      this.active='bagexplorer'


    }
     else if((this.$route.path=="/guide")){
      this.active='guide'


    }
    else if((this.$route.path=="/comingsoon")){
      this.active='comingsoon'


    }
    else if((this.$route.path=="/mint")){
      this.active='mint'


    }
    else{
            this.active='start'

    }
  },
  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
  },
   data:() => ({
      active: 'start',
    })
}
</script>
