<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2" v-if="!register && tokenValid">Redirecting....otherwise,</h1>
      <h1 class="h2" v-if="register && tokenValid">Please register your account</h1>
            <h1 class="h2" v-if="!tokenValid">An Error occurred</h1>

    </div>
          <h2 class="h3" v-if="discordUsername">Welcome {{discordUsername}}</h2>

          <button v-if="!register && tokenValid" type="button" class="btn btn-outline-primary btn-lg" @click="openTwitterLogin">Click here to proceed.</button>
    <div class="layout-form" v-if="register && tokenValid">
   <div class="form-group" :class="{error: validation.hasError('nick')}">
         <label for="pwd">Desired SUPA Nickname:</label>

       <vs-input v-model="nick" placeholder="Nickname" />
        
      <div class="message">{{ validation.firstError('nick') }}</div>
      </div>
   <div class="form-group" :class="{error: validation.hasError('referrer')}">
  <label for="pwd">Referral Code (optional):</label>
       <vs-input v-model="referrer" placeholder="Code" />
             <div class="message">{{ validation.firstError('referrer') }}</div>

         </div>
        
<br>
<div class="form-group">
      <div class="actions">
  <button color="discord" type="button" class="btn btn-success btn btn-space" @click="registerAccount">Sign Up</button>
  <button color="discord" type="button" class="btn btn-primary btn btn-space" @click="reset">Clear Form</button>
      </div>
    </div>
</div>

  </div>

</template>

<script>

import { mapGetters } from "vuex";
import jwt_decode from "jwt-decode";
   import Vue from 'vue';
  import SimpleVueValidation from 'simple-vue-validator';
  const Validator = SimpleVueValidation.Validator;

  Vue.use(SimpleVueValidation);
export default {
  name: "Main",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","isUserLoggedIn","getUserToken","getTwitterUsername","getTwitterUserImg"]),
  },
  data:() => ({
register:true,
tokenValid:false,
referrer:'',
discordUsername:null,
token:'',
nick:'',
  }),
   validators: {
      nick: function(value) {
        return Validator.value(value).required().regex(/^[A-Za-z0-9_]{3,20}$/, 'Alphanumeric (3-20 letters). Underscore is optional');
      },
      referrer: function(value) {
         return Validator.value(value).regex(/^[A-Za-z0-9_]{3,20}$/, 'Alphanumeric (3-20 letters). Underscore is optional');
      },
    },
  methods: {
      registerAccount: function() {
          let component = this;
        this.$validate()
          .then(function(success) {
            if (success) {
           
   var data = { jwt: component.token,
                nick: component.nick,
                referrer: component.referrer     };
fetch('https://api2.supa.foundation/signup', {
    
       method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
 
})
.then(response => response.json()) 
.then(json => {
   if (json.error==true){
       console.log(json)
    component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Registration Error!',
            text: json.response
          })
  }else{

component.$store.dispatch("accounts/connectDiscord",{token:json.token});
component.$router.push({ name: 'home' }); 
  }})




            }
          });
      },
      reset: function() {
        this.nick = '';
        this.referrer = '';
       
        this.validation.reset();
      },
    openTwitterLogin: function(){
let component = this;
	  	   component.$store.dispatch("accounts/connectDiscord",{token:component.$route.params.id});
component.$router.push({ name: 'home' });    },
   

  },
  created: function() {
let component = this;
if(this.$route.params.ref){
   this.referrer= this.$route.params.ref
}
if(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(component.$route.params.id)==true){
    this.tokenValid=true
this.token=this.$route.params.id.toString();
 var decoded = jwt_decode(component.$route.params.id.toString());     
 
 if (decoded.nick==''||decoded.nick==undefined){
component.register=true;

if(decoded.discordName){
    component.discordUsername=decoded.discordName
}
 }else{
     component.register=false;
   component.$store.dispatch("accounts/connectDiscord",{token:component.$route.params.id});

	  	         setTimeout( () => component.$router.push({ name: 'home'}), 3000);
 }
}else{
this.tokenValid=false
}
//this.$router.push({ name: 'home' });

},
destroyed: function() {

}
}
</script>
<style scoped>
.form-group label{
    color:#fff;
}
.message {
    color:red;
}
.btn-space {
  margin: 5px;
}
</style>
