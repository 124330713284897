<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Mint the SUPAVERSE COMIC ISSUE #1:Chapter 1, Page 1 NFT</h1>
    </div>
 <div class="bg-set">
<h3 class="h3">0 NFTs left </h3> 
<div class="med-text">
<p class="med-text">Merry Christmas Everyone!</p>
<p class="med-text">Lots of ❤️ from the SUPA Foundation Team</p>

</div>
  <button  @click="mintNFT" :disabled="!(isUserConnected && NFTJSONArray.length<1 && getChainName=='Fantom Network1' && getSUPAVerseComicI1C1P1RemainingNFTs>0 && getUserMintedSUPAVerseComicI1C1P1NFT==false)" type="button" class="btn btn-success btn-space">Mint 🔥</button>
<p v-if="!isUserConnected || getChainName!='Fantom Network'" >Please connect your wallet to Fantom Opera Network to proceed {{getChainName}}</p>

<div class="small-text">
<p class="small-text">Minting Smart Contract: <a href="https://ftmscan.com/address/0x55B97E580388911ca92E59B3c5A6ff5c7758C62F" target="_blank">FTMScan</a></p>

</div>
<div class="med-text">
<p class="med-text">Troubleshooting: Disconnect/Reconnect your wallet or Re-Login</p>

</div>
<div v-if=" NFTJSONArray.length>0" class="displayNFT">
       <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Your SUPAVERSE COMIC ISSUE #1:Chapter 1, Page 1 NFT</h1>
    </div>
<div  v-for="(purchases,index) in NFTJSONArray" :key="index">
<div class="card" style="width: 1334px;">
  <img :src=purchases.image class="card-img-top">
  <div class="card-body">
   
        <h5 class="card-title">{{purchases.name}}</h5>

    <p class="card-text">{{purchases.description}}</p>
           <p class="card-text">Paintswap: <a target="blank" :href="'https://paintswap.finance/marketplace/assets/0x55B97E580388911ca92E59B3c5A6ff5c7758C62F/'+purchases.token_id">View NFT</a></p>

  </div>
</div>
</div>
  </div>
 </div>
 
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">SUPA.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import jwt_decode from "jwt-decode";
import SUPAVerseComicI1C1P1 from "../contracts/SUPAVerseComicI1C1P1.json";
export default {
  name: "MintI1C1P1",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers","isUserLoggedIn","getAccountDetails"]),
        ...mapGetters("contracts", ["getSUPAVerseComicI1C1P1RemainingNFTs","getSUPAVerseComicI1C1P1Abi", "getSUPAVerseComicI1C1P1Address","getUserMintedSUPAVerseComicI1C1P1NFT"]),
  },
  created() {
  
         this.$store.dispatch("accounts/getIsLoggedIn");
    //this.$store.dispatch("contracts/storeNFTAbi",{name:"SUPAVerseComicI1C1P1"});

    this.$store.dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1",total:2000});

    this.$store.dispatch("contracts/storeNFTAddress",{name:"SUPAVerseComicI1C1P1"});
    
    if (!this.getProviderEthers) {
             //document.location.href="/";
              if (history.length == 0) {
     //  document.location.href="/";
    } else {
        history.go(-1);
    }

    } else {
      // get the contract instance
      let signer = this.getProviderEthers.getSigner(); 
      console.log("ADDRESS",this.getProviderEthers.getSigner())
 
      this.contract = new ethers.Contract(this.getSUPAVerseComicI1C1P1Address, SUPAVerseComicI1C1P1.abi, signer);
      let component = this;
      // set event listener
        this.getNFTs()
      this.contract.on("mintSuccessful", (tokenId) => {
        component.callMintSuccessful(tokenId);
        // show a toast
     
     
        component.$store.dispatch("contracts/fetchRemainingNFTs",{name:"SUPAVerseComicI1C1P1",total:2000});
    
        // refresh the num value
        // component.$store.dispatch("contracts/fetchNum");
      });
    }
  },
  data() {
    return {
      contract: null,
      purchasedArray:[],
      count:0,
      totalNFTs:0,
      NFTJSONArray:[]
    }
  },

   methods: {
     async getNFTs(){
 
       let component=this
      
       let countNFTs=await this.contract.balanceOf(this.getActiveAccount)
        this.totalNFTs=countNFTs.toNumber();
          
     
    
        for(let i=0;this.totalNFTs>i;i++){
         
         let tokenIndex=await this.contract.tokenOfOwnerByIndex(this.getActiveAccount,i)
         let token_id=tokenIndex.toNumber()
           fetch('https://supafoundation.mypinata.cloud/ipfs/QmarkdLTyu7yWWp4SktNnF8Fx9LpKFA6eJh29EzCMR8kjm/1.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
             json.token_id=token_id
            
           component.NFTJSONArray.push(json)
           
          });

        }
        
     },
     async callMintSuccessful(tokenId){
       let component=this
   let getOwner = await this.contract.ownerOf(tokenId);
        if(this.getActiveAccount.toLowerCase()==getOwner.toLowerCase() && this.count<1)
        {
          this.count++
   this.$toasted.show('Congrats! You have successfully minted the SUPAVERSE COMIC SERIES NFT!' , {
          type: 'success',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
               fetch('https://supafoundation.mypinata.cloud/ipfs/QmarkdLTyu7yWWp4SktNnF8Fx9LpKFA6eJh29EzCMR8kjm/1.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
            json.token_id=tokenId
           component.NFTJSONArray.push(json)
          });

        }

     },
  //      async callMintError(){
       
  //  this.$toasted.show('Could not mint NFT' , {
  //         type: 'error',
  //         duration: 5000,
  //         theme: "bubble",
  //         position: "top-center"
  //       });
           

       

     
         async mintNFT() {
           var token=localStorage.getItem('jwtToken').toString();

    var decoded = jwt_decode(token);
      let component=this
  fetch("https://api2.supa.foundation/mintOG/"+token)
    .then(response => response.json())
    .then(async data => {
      if(data.error===true){
        component.$toasted.show('Error: Please check login details & that you have the SUPA Recruit Role', {
          type: 'error',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
      } else {
         
        await this.contract.mint(decoded._id,data.response.signature);
      }
      
      
    })
    },
   
  },
}
</script>
<style scoped>
.displayNFT p{
  color:#000;
    font-size:14px;

}
.displayNFT h5{
  color:#000;
}
.displayNFT a{
  color:#000;
  font-size:14px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.med-text p{
 font-size:18px;
}
.med-text a{
 font-size:18px;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
