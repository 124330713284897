<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Frequently Asked Questions</h1>
    </div>

<div class="accordion" id="accordionGame">
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       What is the purpose of the Portal
      </button>
    </h6>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionGame">
      <div class="accordion-body">
The Portal will be the hub for all SUPA Foundation related activity. The FUTURE goal is for it to have NFT minting, NFT farming, Secondary Marketplace and mini games.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       What is sFuel?
      </button>
    </h6>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionGame">
      <div class="accordion-body">
        sFuel is our loyalty based points programme. It will be used to redeem exclusive in-game items. It will not be for resale. However, you will be able to sell the exclusive in-game items that you obtain using sFuel.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
How long will the sFuel programme last for?
      </button>
    </h6>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We plan to have sFuel running until we launch the first mini-games. Why? We want to limit sFuel for early adopters only!
      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
What are the upcoming NFT Giveaways?
      </button>
    </h6>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We will be giving away OG collectible NFTs(not Viruses or SUPACells) which are unique and have in-game utility. Following this we plan to do some giveaways for our SUPAVerse comic NFT series as well.
      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
How do i qualify for the NFT Giveaways?
      </button>
    </h6>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionGame">
      <div class="accordion-body">
You will need to be part of the Genesis Crew on Discord! We may also only initially open to those who also have the SUPA Titan role as well as they are the ones who are active in our Community!
    </div>
  </div></div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
How do I redeem the NFT Giveaway?  </button>
    </h6>
    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionGame">
      <div class="accordion-body">
If you qualify, you will be entitled to one NFT per Discord account. Please make sure you are on Fantom Network and have a minimum FTM amount to pay for gas.
      </div>
    </div>
  </div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
When will the OG NFT Giveaway happen?
      </button>
    </h6>
    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We anticipate this will happen sometime around the second to third week of Dec 2021. Please stay tuned to our Social Media for the announcement.
      </div>
    </div>
  </div>
   <div class="accordion-item">
    <h6 class="accordion-header" id="headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
Have you read our SUPAVerse Story yet?
      </button>
    </h6>
    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionGame">
      <div class="accordion-body">
It's available on our discord channel! 01010011 01010101 01010000 01000001 00101101 01001001 01001110 01010100 01010110 00110000 00110001

      </div>
    </div>
  </div>
</div>
    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">SUPA.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQ",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
a {
  color:#000;
  font-size:14px;
}

</style>
