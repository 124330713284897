<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Mint the SUPA Vortex OG NFT</h1>
    </div>
 <div class="bg-set">
<h3 class="h3">{{getSUPAVortexRemainingBalance}} OG Vortex NFTs left </h3> 
<div class="med-text">
<p class="med-text">This is to thank you all OG Supporters! 🚀</p>
<p class="med-text">Don't forget to show it off! </p>

</div>
  <button  @click="mintNFT" :disabled="!(isUserConnected && NFTJSONArray.length<1 && getChainName=='Fantom Network' && getSUPAVortexRemainingBalance>0 && getUserMintedSUPAVortexNFT==false)" type="button" class="btn btn-success btn-space">Mint 🔥</button>
<p v-if="!isUserConnected || getChainName!='Fantom Network'" >Please connect your wallet to Fantom Opera Network to proceed {{getChainName}}</p>

<div class="small-text">
<p class="small-text">Minting Smart Contract: <a href="https://ftmscan.com/address/0x7BB15b49059270517a72f949A8b3f146740111a5" target="_blank">FTMScan</a></p>

</div>
<div class="med-text">
<p class="med-text">Please be patient once you have minted! If it does not show up, refresh the page</p>
<p class="med-text">You can also try clicking <a href="#" @click="getNFTs">HERE</a></p>

</div>
<div v-if=" NFTJSONArray.length>0" class="displayNFT">
       <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Your Minted SUPA OG Vortex</h1>
    </div>
<div  v-for="(purchases,index) in NFTJSONArray" :key="index">
<div class="card" style="width: 600px;">
  <img :src=purchases.image class="card-img-top">
  <div class="card-body">
   
        <h5 class="card-title">{{purchases.name}}</h5>

    <p class="card-text">{{purchases.description}}</p>
           <p class="card-text">Paintswap: <a target="blank" :href="'https://paintswap.finance/marketplace/assets/0x7BB15b49059270517a72f949A8b3f146740111a5/'+purchases.edition">View NFT</a></p>

  </div>
</div>
</div>
  </div>
 </div>
 
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">SUPA.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import jwt_decode from "jwt-decode";
export default {
  name: "MintOGVortex",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers","isUserLoggedIn","getAccountDetails"]),
        ...mapGetters("contracts", ["getSUPAVortexRemainingBalance","getSUPAVortexAbi", "getSUPAVortexAddress","getUserMintedSUPAVortexNFT"]),
  },
  created() {
  
         this.$store.dispatch("accounts/getIsLoggedIn");
    this.$store.dispatch("contracts/storeSUPAVortexAbi");

    this.$store.dispatch("contracts/fetchRemainingSUPAVortexNFTs");

    this.$store.dispatch("contracts/storeSUPAVortexAddress");
    
    if (!this.getProviderEthers) {
             //document.location.href="/";
              if (history.length == 0) {
     //  document.location.href="/";
    } else {
        history.go(-1);
    }

    } else {
      // get the contract instance
      let signer = this.getProviderEthers.getSigner(); 
      this.contract = new ethers.Contract(this.getSUPAVortexAddress, this.getSUPAVortexAbi, signer);
      let component = this;
      // set event listener
        this.getNFTs()
      this.contract.on("mintSuccessful", (tokenId) => {
        component.callMintSuccessful(tokenId);
        // show a toast
     
     
        component.$store.dispatch("contracts/fetchRemainingSUPAVortexNFTs");
    
        // refresh the num value
        // component.$store.dispatch("contracts/fetchNum");
      });
    }
  },
  data() {
    return {
      contract: null,
      purchasedArray:[],
      count:0,
      totalNFTs:0,
      NFTJSONArray:[]
    }
  },

   methods: {
     async getNFTs(){
 
       let component=this
      
       let countNFTs=await this.contract.balanceOf(this.getActiveAccount)
        this.totalNFTs=countNFTs.toNumber();
          console.log(await this.contract.tokenURI(1));
      console.log("show NFTs")
    
        for(let i=0;this.totalNFTs>i;i++){
         
           let tokenIndex=await this.contract.tokenOfOwnerByIndex(this.getActiveAccount,i)
           fetch('https://gateway.pinata.cloud/ipfs/QmZ6GdhiBGQHC2NktskFUAbhUibLJMFK62pWarh5Zrudta/'+tokenIndex+'.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
          
           component.NFTJSONArray.push(json)
           
          });

        }
        
     },
     async callMintSuccessful(tokenId){
       let component=this
   let getOwner = await this.contract.ownerOf(tokenId);
        if(this.getActiveAccount.toLowerCase()==getOwner.toLowerCase() && this.count<1)
        {
          this.count++
   this.$toasted.show('Congrats! You have successfully minted the SUPA OG VORTEX NFT!' , {
          type: 'success',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
               fetch('https://gateway.pinata.cloud/ipfs/QmZ6GdhiBGQHC2NktskFUAbhUibLJMFK62pWarh5Zrudta/'+tokenId+'.json', { 
            method: 'GET'
          })
          .then(function(response) { return response.json(); })
          .then(function(json) {
           component.NFTJSONArray.push(json)
          });

        }

     },
  //      async callMintError(){
       
  //  this.$toasted.show('Could not mint NFT' , {
  //         type: 'error',
  //         duration: 5000,
  //         theme: "bubble",
  //         position: "top-center"
  //       });
           

       

     
         async mintNFT() {
           var token=localStorage.getItem('jwtToken').toString();

    var decoded = jwt_decode(token);
      let component=this
  fetch("https://api2.supa.foundation/mintOG/"+token)
    .then(response => response.json())
    .then(async data => {
      if(data.error===true){
        component.$toasted.show('Error: Please check login details & that you have the SUPA Recruit Role', {
          type: 'error',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
      } else {
         
        await this.contract.mint(decoded._id,data.response.signature);
      }
      
      
    })
    },
   
  },
}
</script>
<style scoped>
.displayNFT p{
  color:#000;
    font-size:14px;

}
.displayNFT h5{
  color:#000;
}
.displayNFT a{
  color:#000;
  font-size:14px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.med-text p{
 font-size:18px;
}
.med-text a{
 font-size:18px;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
