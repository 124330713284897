<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2" v-if="!isUserLoggedIn">Welcome, Recruit!</h1>
        <h1 class="h2" v-if="isUserLoggedIn">Welcome, {{getSUPAUsername.toUpperCase()}}!</h1>
    </div>
     <div class="row">
            <div class="col-lg-6 mb-4">
    <div class="bg-set">
      <h2 class="h3" v-if="!isUserLoggedIn">Please login to Discord to proceed!</h2>
<h2 class="h3" v-if="isUserLoggedIn">Profile</h2>

<div v-if="!isUserLoggedIn" class="warning-section">
           <p class="warning-text">We do not condone the creation of multiple accounts. </p>
             
               </div>
                  <div class="form-check" v-if="!isUserLoggedIn">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  v-model="checked">
  <label class="form-check-label" for="flexCheckDefault">
    I have read the above and agree
  </label>
</div>

   <p v-if="!isUserLoggedIn">
      

      <button color="discord" type="button" class="btn btn-primary btn" @click="openDiscordLogin" :disabled="checked==false"><i class='bx bxl-discord' >Login with Discord</i></button>

    </p>




<div class="small-text" v-if="isUserLoggedIn">
  <img :src="getAccountDetails.photoURL" alt="Avatar" class="avatar">
<p class="small-text">Discord: {{getDiscordUsername}}</p>
<p class="small-text" v-if="getAccountDetails.hasGenesis">Genesis Crew (NFT Whitelist): ✅</p>
<p class="small-text" v-if="!getAccountDetails.hasGenesis">Genesis Crew (NFT Whitelist): ❌</p>
<!-- <p class="small-text" v-if="getAccountDetails.hasTitan">SUPA Titan: ✅</p>
<p class="small-text" v-if="!getAccountDetails.hasTitan">SUPA Titan: ❌</p> -->
<p class="small-text" v-if="getAccountDetails.hasIDO">IDO Whitelist: ✅</p>
<p class="small-text" v-if="getAccountDetails.hasIDO">Wallet address for IDO whitelist: {{(getAccountDetails.ethAddr==undefined)? "Not set" :getAccountDetails.ethAddr}} </p>
 <p class="small-text" v-if="getAccountDetails.hasIDO && getAccountDetails.emailAddr!=''">Email address for IDO whitelist: {{getAccountDetails.emailAddr}} </p>

  <div class="layout-form" v-if="showEthAddrForm">
   <div class="form-group" :class="{error: validation.hasError('ethAddr')}">
         <label for="pwd">Fantom Opera Network Wallet Address:</label>

       <vs-input v-model="ethAddr" placeholder="Wallet" />
        
      <div class="message">{{ validation.firstError('ethAddr') }}</div>
      </div>
   <div class="form-group" :class="{error: validation.hasError('emailAddr')}">
  <label for="pwd">Email address to be notified (optional):</label>
       <vs-input v-model="emailAddr" placeholder="Email" />
             <div class="message">{{ validation.firstError('emailAddr') }}</div>

         </div>
        
<br>
<div class="form-group">
      <div class="actions">
  <button color="discord" type="button" class="btn btn-success btn btn-space" @click="updateEthAddr">Update</button>
  <button color="discord" type="button" class="btn btn-primary btn btn-space" @click="editEthAddr">Cancel</button>
      </div>
    </div>
</div>
 <p class="small-text" v-if="!isUserConnected ">Connected to Fantom Network: ❌</p>
  <p class="small-text" v-if="isUserConnected && getChainName=='Fantom Network' && isUserLoggedIn ">Connected to Fantom Network: ✅</p>
<div class="warning-section">
 

 <p class="small-text" v-if="isUserConnected && getChainName!='Fantom Network' && isUserLoggedIn">Please connect to Fantom Network by selecting it in your wallet or <a href="#" @click="addNetwork">CLICK HERE</a></p>
</div>

</div>
<p></p><p></p><p></p>
</div>
</div>
     <div v-if="isUserLoggedIn" class="col-lg-6 mb-4">
    <div class="bg-set">
<h2 class="h3" v-if="isUserLoggedIn">sFuel</h2>
    <p></p>
   

              


<div class="small-text" v-if="isUserLoggedIn">



<p class="small-text" v-if="showCounter">sFuel Farming: Active ✅</p>
<p class="small-text" v-if="!showCounter">sFuel Farming: Inactive ❌</p>

        <p> <span>Total: {{ currentBal }} sFuel</span></p>
              <p v-if="showCounter">   <span>Remaining time: {{ counterString }}</span></p>

                <p v-if="showCounter"> <span>Rate: {{ currentRate }} sFuel/hr</span></p>
                 <p class="small-text" >Referral link: <button v-clipboard:copy='"https://portal.supa.foundation/r/"+getSUPAUsername'>Click to Copy</button>

</p>

      <button color="discord" type="button" class="btn btn-primary btn" v-if="!showCounter" @click="goMine">Farm sFuel</button>

   
</div>
<p></p><p></p><p></p>
</div>
</div>


</div>
    <!-- <p v-if="isUserConnected">
      <strong>Your current chain:</strong> {{getChainName}}
    </p> -->

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->

      <div v-if="isUserLoggedIn" class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Your sFuel Team. </h1>
       
    </div>
        <div class="bg-set" v-if="isUserLoggedIn">
                <h2 class="h3">Total {{getGroupDetails.length}} members</h2>

   <div class="card flex-row flex-wrap" v-for="teamMember in getGroupDetails" v-bind:key="teamMember.nick">
        <div class="card-header border-0">
             <img :src="teamMember.photoURL" alt="Avatar" class="avatar">
        </div>
        <div class="card-block px-2">
          
          
            <h4 class="card-title">{{(teamMember.discordName===undefined)? teamMember.name :teamMember.discordName}}</h4>
            <p class="card-text">@{{teamMember.nick}}  <span class="logged-in" v-if="teamMember.endTime>=timeNow">● Active</span>
          <span class="logged-out" v-if="teamMember.endTime<timeNow">● Inactive</span></p>
        </div>
    
       
    </div>
 
        </div>


      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Important Notes/Announcements</h1>
       
    </div>
    
     <div class="bg-set">
          
          <h2 class="h3">Updated 30 Dec 21 CODE:ZETA</h2>

    <p>TGE & Public Listing will now happen on the 11th of Jan 2022. In the meantime, we hope you are enjoying the holidays and we wish you a Happy New Year! Also don't forget to mint our Comic Series NFT! </p>

   
        </div>
      <div class="bg-set">
          
          <h2 class="h3">Updated 17 Dec 21</h2>

    <p>NFT OG VORTEX Minting is now open to SUPA Titans and SUPA Recruits!. Make sure you click connect wallet first!</p>

   
        </div>
        <div class="bg-set">
          
          <h2 class="h3">Updated 15 Dec 21</h2>

    <p>NFT OG MINTING IS LIVE! You will need the Genesis Crew Role and some gas on Fantom Opera Network to mint! Thank you for all your support!</p>
    <p>Make sure you connect your Metamask or equivalent wallet to Fantom Opera Network First! Otherwise you wont see the link.</p>

   
        </div>
  <div class="bg-set">
          
          <h2 class="h3">Updated 11 Dec 21</h2>

    <p>1) sFuel is in-game currency and will NOT be withdrawable. You may use it later to redeem in-game items of which you can choose to resell.</p>
        <p>2) Our first giveaway will be the OG NFT redeemable to for the first 1000 Genesis Crew Members who are active on Discord. It will have in-game utility later.</p>

   
        </div>

 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">SUPA.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ref, onUpdated } from '@vue/composition-api'
import {useStore} from '@vueblocks/vue-use-vuex'
import * as dayjs from 'dayjs'
   import Vue from 'vue';
import * as duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
  import SimpleVueValidation from 'simple-vue-validator';
  const Validator = SimpleVueValidation.Validator;

  Vue.use(SimpleVueValidation);
export default {
  name: "Home",
  computed: {

        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
     ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers","isUserLoggedIn","getUserToken","getDiscordUsername","getDiscordUserImg","getSUPAUsername","getDiscordRole","getAccountDetails","getGroupDetails"]),

  },
  created: function() {
     this.$store.dispatch("accounts/getIsLoggedIn");
     
//let component = this;

// window.addEventListener('message', function(e) {
// 	// e.data hold the message from child

// 	//var decoded = jwt_decode(e.data);
// 	if(e.data!==undefined && e.data.data===undefined){
// 	  ///	   component.$store.dispatch("accounts/connectTwitter",{token:e.data});
//   //console.log(e.data)
// 	}
//     // component.$store.dispatch("accounts/getUserToken");
//     // component.$store.dispatch("accounts/getTwitterUsername");
//     //   component.$store.dispatch("accounts/getIsLoggedIn");
    
// 	//save jwt TOKEN
// 	//check token with server then showw welcome message
// 	//change isUserLogged in to true
// 	//use token to search for users
// 	//allow selection of user
// 	//allow sending tokens to contract
// 	//allow withdrawing from contract
// 	//faucet to withdraw to wallet if wallet is empty
// } , false);
},
// destroyed: function() {
//   window.removeEventListener('message', function() {
// } , false);
// },
//   mounted(){

//   },
    methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    onClickButton () {
            this.isBarOpen=!this.isBarOpen

      this.$emit('childToParent', this.isBarOpen)
     
    },
    gotoMint (){
     this.$router.replace({ path: `/mint` })
    },
    editEthAddr(){
this.showEthAddrForm=!this.showEthAddrForm

    },
    addNetwork(){
window.ethereum.request({
method: 'wallet_addEthereumChain',
params: [{
chainId: '0xfa',
chainName: 'Fantom Opera Network',
nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18
},
rpcUrls: ['https://rpc.ftm.tools/'],
blockExplorerUrls: ['https://ftmscan.com']
}]
})
.catch((error) => {
console.log(error)
}) 
    },
         updateEthAddr: function() {
          let component = this;
        this.$validate()
          .then(function(success) {
            if (success) {
           
   var data = { jwt: localStorage.getItem('jwtToken'),
                ethAddr: component.ethAddr,
                emailAddr: component.emailAddr    };
fetch('https://api2.supa.foundation/updatewallet', {
    
       method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
 
})
.then(response => response.json()) 
.then(json => {
   if (json.error==true){
       console.log(json)
    component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Error',
            text: "Could not update information"
          })
  }else{

     component.$store.dispatch("accounts/getIsLoggedIn");
     component.editEthAddr(false)

  }})




            }
          });
      },
      openDiscordLogin: function(){
   
                        window.location.href = 'https://api2.supa.foundation/auth/discord';

    },
  },
   data:() => ({
      active: 'start',
      isBarOpen:false,
        timeNow:Math.round(new Date() / 1000),
  checked : false,
  showEthAddrForm:false,
  ethAddr:"",
  emailAddr:"",
    }),
       validators: {
      ethAddr: function(value) {
        return Validator.value(value).required().regex(/^0x[a-fA-F0-9]{40}$/, 'Please enter a valid ERC20/FRC20 Wallet address');
      },
      emailAddr: function(value) {
         return Validator.value(value).regex(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter a valid email address');
      },
    },
  setup(){
    const store = useStore()
  

   
      

    let balEnd = ref(0);
  let balStart = ref(0);
  const progress=ref(0)
    const currentBal=ref(0);
    const counterString=ref("")
      const secondsRemaining=ref(0)
    const showCounter=ref(false)
    const currentRate=ref(0);
    let timerz=ref(null);
    let mined=ref("false");
   // let counterss=ref(0)
    let countz=ref(0)
     const updateDisplaySetup=(balStart,balEnd,endTime)=>{
  // console.log(counterss.value++)
   const timestamp = Math.round(new Date() / 1000);
   const diff=parseInt(endTime, 10)-timestamp
  

      if(diff>0){
        
        if(balEnd.value==undefined){
                     


      currentBal.value=((((balEnd-balStart)*( (86400-diff) / 86400))+balStart)/1000).toFixed(3);
        counterString.value=dayjs.duration(diff*1000).format('HH:mm:ss')
        secondsRemaining.value=diff;
        showCounter.value=true
        currentRate.value=((balEnd-(currentBal.value*1000))/(secondsRemaining.value/3600)/1000).toFixed(1)
        }
  
       

      else{
                

       showCounter.value=true
        currentBal.value=((((balEnd.value-balStart.value)*( (86400-diff) / 86400))+balStart.value)/1000).toFixed(3);
        counterString.value=dayjs.duration(diff*1000).format('HH:mm:ss')
        secondsRemaining.value=diff;
        currentRate.value=((balEnd.value-(currentBal.value*1000))/(secondsRemaining.value/3600)/1000).toFixed(1)
       }
       
       let counter=ref(0)
         if(counter.value==0){
           counter.value++
       
         }
if(secondsRemaining.value<=0){
  
          clearInterval(timerz.value)
         
         secondsRemaining.value=0
       
          return
        }
     

      //  Interval.addTask(runTimerSetup);
       

      } else{

          clearInterval(timerz.value)
      counterString.value=dayjs.duration(0*1000).format('HH:mm:ss')
         secondsRemaining.value=0
        currentBal.value=(balEnd.value/1000).toFixed(3);
       showCounter.value=false
    
      }
   } 


  onUpdated(() => {
     

    if(countz.value==0 || mined.value==true){
                
      countz.value++
      mined.value=false
     
 balEnd.value = (store.state.accounts.accountDetails.balEnd === undefined) ? 0 : store.state.accounts.accountDetails.balEnd;
   balStart.value = (store.state.accounts.accountDetails.balStart === undefined) ? 0 : store.state.accounts.accountDetails.balStart;
//console.log(store.state.accounts.accountDetails)
  timerz.value=setInterval(() => {
                updateDisplaySetup(balStart,balEnd,store.state.accounts.accountDetails.endTime);
            },1000);
  }
    })
    const deleteDiscord=()=>{

          let component = this;

let idToken=localStorage.getItem('jwtToken')
if(idToken!=null){
  // Send token to your backend via HTTPS
  // ...
     let formData = new FormData();
formData.append('token', idToken);
fetch('https://api.supa.foundation/deleteaccount', {
  method: "POST",
  body: formData,
 
})
.then(response => response.json()) 
.then(json => {
  if (json.error==true){
     component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Error',
            text: "We could not delete this account as it appears to be already linked."
          })
     

  }else{
store.dispatch("accounts/disconnectWeb3Modal");
store.dispatch("accounts/disconnectDiscord");


  } })
    }}
     const goMine=()=>{
     
          let component = this;

let idToken=localStorage.getItem('jwtToken')
if(idToken!=null){
  // Send token to your backend via HTTPS
  // ...
     let formData = new FormData();
formData.append('token', idToken);
fetch('https://api.supa.foundation/dmine', {
  method: "POST",
  body: formData,
 
})
.then(response => response.json()) 
.then(json => {
  if (json.error==true){
     component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Error!',
            text: 'Unable to start sFuel farming process'
          })
     

  }else{

       let formData = new FormData();
formData.append('token', idToken);
fetch('https://api.supa.foundation/refreshDiscordUser', {
  method: "POST",
  body: formData,
 
})
.then(response => response.json()) 
.then(json => {
  if (json.userinfo.length==0){
      component.$vs.notification({
           color: 'danger',
            position:'top-center',
            title: 'Error!',
            text: 'An error occurred refreshing data'
          })
       

  }else{
   // showCounter.value=true
  const balEnd = (json.userinfo[0].balEnd === undefined) ? 0 : json.userinfo[0].balEnd;
  const balStart = (json.userinfo[0].balStart === undefined) ? 0 : json.userinfo[0].balStart;
    updateDisplaySetup(balStart,balEnd,json.userinfo[0].endTime)
    mined.value=true;

    store.commit("accounts/saveAccountDetails",json.userinfo[0])
    store.commit("accounts/saveGroupInfo",json.group)
  
      
  }
  
 
})
.catch(err => console.log(err));
   //  showCounter.value=true

      
  }
  
 
})
.catch(err => console.log(err));

    }
  
 }
  
    const answer=ref(0)
    const step=ref(0)
    const setAnswer=(val)=>{
      if(val==1 || val==2){
      answer.value=val
      }

    }
      const skipToMint=()=>{
      
      answer.value=1
      step.value=2

    }
     const reset=()=>{
      
      answer.value=0
      step.value=0

    }
    return{
      deleteDiscord,
      step,
      setAnswer,
      answer,
      skipToMint,
      reset,
      goMine,
    currentRate,
        currentBal,
        counterString,
        secondsRemaining,
     progress,
      balEnd,
      showCounter
    }
  }
}
</script>
<style scoped>
.form-group label{
    color:#fff;
}
.message {
    color:red;
}

.btn-space {
  margin: 5px;
}
.btn-warning, .btn-warning:active, .btn-warning:visited {
    background-color: #FF8F00 !important;
}
.btn-warning:hover{
      background-color: #FF6F00 !important;

}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.small-text p{
 font-size:16px;
}
.warning-text p{
 font-size:16px;
 color:red;
}
.warning-text a{
 font-size:16px;
}
.warning-section p{
  color: red;
}
.small-text a{
 font-size:16px;
}
.card-text {
 font-size:16px;
 color:#000;
}
.card-title {
 
 color:#000;
}
.avatar {
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.card{
  margin-bottom:15px;
}
.card-header{
  background-color: #ffffff;
}
.logged-in {
  color: green;
}

.logged-out {
  color: red;
}
.form-check {
  color: #fff;
}
</style>
