import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home";

import SignIn from "./pages/SignIn";
import Refer from "./pages/Refer";


import MintI1C1P1 from "./pages/MintI1C1P1"
import FAQ from "./pages/FAQ";
import Guide from "./pages/Guide";
import OG from "./pages/OG";
import ComingSoon from "./pages/ComingSoon";
import MintOGVortex from "./pages/MintOGVortex";
//import ViewBag from "./pages/ViewBag";

Vue.use(Router);

export default new Router({
    // Make sure the server can handle the history mode
    // If not, set it to hash (or delete the mode)
    // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/sign-in/:id/:ref?",
            name: "signIn",
            component: SignIn
        },
        {
            path: "/r/:ref",
            name: "refer",
            component: Refer
        },
       
       
       
        {
            path: "/faq",
            name: "FAQ",
            component: FAQ
        },
        {
            path: "/mintog",
            name: "MintOGVortex",
            component: MintOGVortex
        },
        {
            path: "/minti1c1p1",
            name: "MintI1C1P1",
            component: MintI1C1P1
        },
        {
            path: "/guide",
            name: "Guide",
            component: Guide
        },
        // {
        //     path: "/bagexplorer",
        //     name: "BagExplorer",
        //     component: BagExplorer
        // },
        // { path: '/v/:id', component: ViewBag, name:"ViewBag" },
        // { path: '/viewbag/:id', component: ViewBag, name:"ViewBag" },
        {
            path: "/og",
            name: "OG",
            component: OG
        },
        {
            path: "/comingsoon",
            name: "ComingSoon",
            component: ComingSoon
        },
        
        
      
        { path: "*", component: ComingSoon }
       
      
    ],
    linkActiveClass: "active"
});