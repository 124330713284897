<template>

<div>
   <div v-if="isBarOpen"  @click=" onClickButton" class="navbar-backdrop" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"></div>

<header class="navbar-padding">
      <vs-navbar color="#212121" text-white square center-collapsed v-model="active">
        <template #left>
          <a href="/" class="logo-link">
        <img src="/logo1.png" alt="" height="40px" width="40px"></a>
           <a href="/" class="logo-link">       <h5>SUPA Portal</h5>
              </a>
 
        </template>
         

       
        <template #right>
           <vs-button color="#5ac4bd" border @click="onClickButton" class="d-md-none collapsed" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
  <i class='bx bx-menu '></i>
         </vs-button>
         
                   <vs-button color="twitter" href="https://twitter.com/SUPAFoundation"  icon><i class='bx bxl-twitter ' ></i></vs-button>

         <vs-button color="discord" href="https://discord.gg/nyvcnX7TFr" icon  ><i class='bx bxl-discord' ></i></vs-button>

          <vs-button color="#FE4657" href="#"  border v-if="isUserConnected && getChainName!='Fantom Network' && isUserLoggedIn" @click="addNetwork" >Wrong Network! Switch to Fantom</vs-button>

            
          <vs-button color="#5ac4bd" href="#"  border v-if="!isUserConnected && isUserLoggedIn" @click="connectWeb3Modal" >Connect Wallet</vs-button>
        <vs-button color="#5ac4bd" href="#" border v-if="isUserConnected" @click="disconnectWeb3Modal" >Disconnect {{getActiveAccount.substring(0, 7)}}...</vs-button>
        <vs-button color="#FE4657" href="#" border v-if="isUserLoggedIn" @click="logout" >LogOut</vs-button>

        </template>
      </vs-navbar>
   
</header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters("accounts", ["getActiveAccount", "isUserConnected", "getWeb3Modal","isUserLoggedIn","getChainName"]),
  },
 
  created() {
    this.$store.dispatch("accounts/initWeb3Modal");
    this.$store.dispatch("accounts/ethereumListener");

  },

  methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    onClickButton () {
            this.isBarOpen=!this.isBarOpen

      this.$emit('childToParent', this.isBarOpen)
      console.log("emitting")
    },
    logout(){
this.$store.dispatch("accounts/disconnectWeb3Modal");
this.$store.dispatch("accounts/disconnectDiscord");

    },

     addNetwork(){
window.ethereum.request({
method: 'wallet_addEthereumChain',
params: [{
chainId: '0xfa',
chainName: 'Fantom Opera Network',
nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18
},
rpcUrls: ['https://rpc.ftm.tools/'],
blockExplorerUrls: ['https://ftmscan.com']
}]
})
.catch((error) => {
console.log(error)
}) 
    },
  },
   data:() => ({
      active: 'start',
      isBarOpen:false,
    })
}
</script>


<style scoped>
.navbar-backdrop {
  z-index: 100001;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  bottom: 0;
}
</style>