<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Welcome, Recruit!</h1>
    </div>
    <div class="bg-set">
      <h2 class="h3" v-if="!isUserLoggedIn">Please login to Discord to proceed!</h2>

    <p></p>
   <p v-if="!isUserLoggedIn">
      

      <button color="discord" type="button" class="btn btn-primary btn" @click="openDiscordLogin"><i class='bx bxl-discord' >Login with Discord</i></button>
    </p>


<p></p><p></p><p></p>
</div>
    <!-- <p v-if="isUserConnected">
      <strong>Your current chain:</strong> {{getChainName}}
    </p> -->

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
   
      


    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">SUPA.Foundation (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ref } from '@vue/composition-api'

export default {
  name: "Home",
  computed: {

        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
     ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers","isUserLoggedIn","getUserToken","getDiscordUsername","getDiscordUserImg",,"getSUPAUsername",,"getDiscordRole"]),

  },
  created: function() {
     this.$store.dispatch("accounts/getIsLoggedIn");
     if(this.$route.params.ref){
   this.referrer= this.$route.params.ref
}
//let component = this;

window.addEventListener('message', function(e) {
	// e.data hold the message from child

	//var decoded = jwt_decode(e.data);
	if(e.data!==undefined && e.data.data===undefined){
	  ///	   component.$store.dispatch("accounts/connectTwitter",{token:e.data});
  console.log(e.data)
	}
    // component.$store.dispatch("accounts/getUserToken");
    // component.$store.dispatch("accounts/getTwitterUsername");
    //   component.$store.dispatch("accounts/getIsLoggedIn");
    
	//save jwt TOKEN
	//check token with server then showw welcome message
	//change isUserLogged in to true
	//use token to search for users
	//allow selection of user
	//allow sending tokens to contract
	//allow withdrawing from contract
	//faucet to withdraw to wallet if wallet is empty
} , false);
},
destroyed: function() {
  window.removeEventListener('message', function() {
} , false);
},
  mounted(){

  },
   
    methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    onClickButton () {
            this.isBarOpen=!this.isBarOpen

      this.$emit('childToParent', this.isBarOpen)
      console.log("emitting")
    },
    gotoMint (){
     this.$router.replace({ path: `/mint` })
    },
      openDiscordLogin: function(){
                        if(this.referrer!=''){
                          console.log(this.referrer)
                        window.location.href = 'https://api2.supa.foundation/auth/discord?state='+this.referrer;

                        }else{
                          window.location.href = 'https://api2.supa.foundation/auth/discord'
                        }

    },
  },
   data:() => ({
      active: 'start',
      isBarOpen:false,
      referrer:'',
    }),
  setup(){
    const answer=ref(0)
    const step=ref(0)
    const setAnswer=(val)=>{
      if(val==1 || val==2){
      answer.value=val
      }

    }
      const skipToMint=()=>{
      
      answer.value=1
      step.value=2

    }
     const reset=()=>{
      
      answer.value=0
      step.value=0

    }
    return{
      step,
      setAnswer,
      answer,
      skipToMint,
      reset
    }
  }
}
</script>
<style scoped>
.btn-space {
  margin: 5px;
}
.btn-warning, .btn-warning:active, .btn-warning:visited {
    background-color: #FF8F00 !important;
}
.btn-warning:hover{
      background-color: #FF6F00 !important;

}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
